<template>
  <div class="add_container">
    <div class="search">
      <form action="/">
        <van-search
          v-model="searchStr"
          show-action
          placeholder="请输入搜索科室或医生"
          @input="onSearch"
          @cancel="onCancel"
          @clear="onClear"
        />
      </form>
    </div>
    <div
      class="result"
      v-if="show"
    >
      <!-- 搜索套餐 -->
      <div class="searchmeal">
        <div class="title">
          <van-icon name="orders-o" />
          <span>体检套餐</span>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item,index) in searchList.searchmeals"
            :key="index"
          >
            <van-radio-group
              v-model="searchRadio"
              direction="horizontal"
            >
              <div class="label">
                <van-radio
                  :name="item.mealname"
                  @click="selectSearchMealClick(item.mealname)"
                />
                <span @click="searchmealListClick(index)">{{item.mealname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="searchmealListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="searchmealListClick(index)"
                />
              </div>
            </van-radio-group>
            <div
              class="block"
              v-if="item.checked"
            >
              <div
                class="detail"
                v-for="(item,index) in item.detail"
                :key="index"
              >
                <div>体检项目：{{item.itemname}}</div>
                <div class="desc">项目描述：{{item.itemintrod}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 搜索项目 -->
      <div class="optionalcomb">
        <div class="title">
          <van-icon name="description" />
          <span>搜索项目</span>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item,index) in searchList.searchcomb"
            :key="index"
          >
            <div class="label">
              <van-checkbox-group
                v-model="combResult"
                @change="selectSearchCombChange"
                direction="horizontal"
              >
                <van-checkbox
                  :name="item.itemname"
                  shape="square"
                />
              </van-checkbox-group>
              <span @click="searchcombListClick(index)">项目名称：{{item.itemname}}</span>
              <van-icon
                name="arrow-down"
                v-if="!item.checked"
                @click="searchcombListClick(index)"
              />
              <van-icon
                name="arrow-up"
                v-if="item.checked"
                @click="searchcombListClick(index)"
              />
            </div>
            <div
              class="desc"
              v-if="item.checked"
            >项目描述：{{item.itemintrod}}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="meal_list"
      v-if="!show"
    >
      <!-- 基础套餐 -->
      <div class="basicmeal">
        <div
          class="title"
          @click="basicmealShowClick"
        >
          <van-icon name="orders-o" />
          <span>{{list.Basicmeal.mealname}}</span>
          <van-icon
            name="arrow-down"
            v-if="!basicmealShow"
          />
          <van-icon
            name="arrow-up"
            v-if="basicmealShow"
          />
        </div>
        <div
          class="list"
          v-if="basicmealShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Basicmeal.detail"
            :key="index"
          >
            <div>项目名称：{{item.itemname}}</div>
            <div class="desc">项目描述：{{item.itemintrod}}</div>
          </div>
        </div>
      </div>
      <!-- 可选套餐 -->
      <div class="optionalmeal">
        <div
          class="title"
          @click="optionalmealShowClick"
        >
          <van-icon name="label-o" />
          <span>可选套餐</span>
          <van-icon
            name="arrow-down"
            v-if="!optionalmealShow"
          />
          <van-icon
            name="arrow-up"
            v-if="optionalmealShow"
          />
        </div>
        <div
          class="list"
          v-if="optionalmealShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Optionalmeals"
            :key="index"
          >
            <van-radio-group
              v-model="radio"
              direction="horizontal"
            >
              <div class="label">
                <van-radio
                  :name="item.mealname"
                  @click="selectMealClick(item.mealname)"
                />
                <span @click="optionalmealListClick(index)">{{item.mealname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="optionalmealListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="optionalmealListClick(index)"
                />
              </div>
            </van-radio-group>
            <div
              class="block"
              v-if="item.checked"
            >
              <div
                class="detail"
                v-for="(item,index) in item.detail"
                :key="index"
              >
                <div>体检项目：{{item.itemname}}</div>
                <div class="desc">项目描述：{{item.itemintrod}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 可选项目 -->
      <div class="optionalcomb">
        <div
          class="title"
          @click="optionalcombShowClick"
        >
          <van-icon name="records" />
          <span>可选项目</span>
          <van-icon
            name="arrow-down"
            v-if="!optionalcombShow"
          />
          <van-icon
            name="arrow-up"
            v-if="optionalcombShow"
          />
        </div>
        <!-- 一般项目 -->
        <div
          v-if="optionalcombShow"
          class="title"
          @click="ybShowClick"
        >
          <van-icon name="description" />
          <span>一般项目</span>
          <van-icon
            name="arrow-down"
            v-if="!ybShow"
          />
          <van-icon
            name="arrow-up"
            v-if="ybShow"
          />
        </div>
        <div
          class="list"
          v-if="ybShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Optionalcomb"
            :key="index"
          >
            <div
              class="block"
              v-if="item.itemtype === 0"
            >
              <div class="label">
                <van-checkbox-group
                  v-model="result"
                  @change="selectCombChange"
                  direction="horizontal"
                >
                  <van-checkbox
                    :name="item.itemname"
                    shape="square"
                  />
                </van-checkbox-group>
                <span @click="optionalcombListClick(index)">项目名称：{{item.itemname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="optionalcombListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="optionalcombListClick(index)"
                />
              </div>
              <div
                class="desc"
                v-if="item.checked"
              >项目描述：{{item.itemintrod}}</div>
            </div>
          </div>
        </div>
        <!-- 检查项目 -->
        <div
          v-if="optionalcombShow"
          class="title"
          @click="jcShowClick"
        >
          <van-icon name="todo-list-o" />
          <span>检查项目</span>
          <van-icon
            name="arrow-down"
            v-if="!jcShow"
          />
          <van-icon
            name="arrow-up"
            v-if="jcShow"
          />
        </div>
        <div
          class="list"
          v-if="jcShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Optionalcomb"
            :key="index"
          >
            <div
              class="block"
              v-if="item.itemtype === 1"
            >
              <div class="label">
                <van-checkbox-group
                  v-model="result"
                  direction="horizontal"
                  @change="selectCombChange"
                >
                  <van-checkbox
                    :name="item.itemname"
                    shape="square"
                  />
                </van-checkbox-group>
                <span @click="optionalcombListClick(index)">项目名称：{{item.itemname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="optionalcombListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="optionalcombListClick(index)"
                />
              </div>
              <div
                class="desc"
                v-if="item.checked"
              >项目描述：{{item.itemintrod}}</div>
            </div>
          </div>
        </div>
        <!-- 检验项目 -->
        <div
          v-if="optionalcombShow"
          class="title"
          @click="jyShowClick"
        >
          <van-icon
            name="completed"
            @click="jyShowClick"
          />
          <span>检验项目</span>
          <van-icon
            name="arrow-down"
            v-if="!jyShow"
          />
          <van-icon
            name="arrow-up"
            v-if="jyShow"
          />
        </div>
        <div
          class="list"
          v-if="jyShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Optionalcomb"
            :key="index"
          >
            <div
              class="block"
              v-if="item.itemtype === 2"
            >
              <div class="label">
                <van-checkbox-group
                  v-model="result"
                  direction="horizontal"
                  @change="selectCombChange"
                >
                  <van-checkbox
                    :name="item.itemname"
                    shape="square"
                  />
                </van-checkbox-group>
                <span @click="optionalcombListClick(index)">项目名称：{{item.itemname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="optionalcombListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="optionalcombListClick(index)"
                />
              </div>
              <div
                class="desc"
                v-if="item.checked"
              >项目描述：{{item.itemintrod}}</div>
            </div>
          </div>
        </div>
        <!-- 其他项目 -->
        <div
          v-if="optionalcombShow"
          class="title"
          @click="qtShowClick"
        >
          <van-icon
            name="bookmark-o"
            @click="qtShowClick"
          />
          <span>其他项目</span>
          <van-icon
            name="arrow-down"
            v-if="!qtShow"
          />
          <van-icon
            name="arrow-up"
            v-if="qtShow"
          />
        </div>
        <div
          class="list"
          v-if="qtShow"
        >
          <div
            class="item"
            v-for="(item,index) in list.Optionalcomb"
            :key="index"
          >
            <div
              class="block"
              v-if="item.itemtype === 3"
            >
              <div class="label">
                <van-checkbox-group
                  v-model="result"
                  direction="horizontal"
                  @change="selectCombChange"
                >
                  <van-checkbox
                    :name="item.itemname"
                    shape="square"
                  />
                </van-checkbox-group>
                <span @click="optionalcombListClick(index)">项目名称：{{item.itemname}}</span>
                <van-icon
                  name="arrow-down"
                  v-if="!item.checked"
                  @click="optionalcombListClick(index)"
                />
                <van-icon
                  name="arrow-up"
                  v-if="item.checked"
                  @click="optionalcombListClick(index)"
                />
              </div>
              <div
                class="desc"
                v-if="item.checked"
              >项目描述：{{item.itemintrod}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttom">
      <div class="total">
        合&nbsp;计：{{totalPrice}}&nbsp;元
      </div>
      <van-button
        type="primary"
        @click="order"
        block
      >确&nbsp;认&nbsp;提&nbsp;交</van-button>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Add",
  data() {
    return {
      // 搜索套餐
      show: false,
      searchStr: "",
      searchRadio: "",
      searchOldRadio: "",
      searchmealPrice: 0,
      //搜索项目
      combResult: [],
      searchcombPrice: 0,
      //金额
      totalPrice: 0,
      basicmealPrice: 0,
      optionalmealPrice: 0,
      optionalcombPrice: 0,
      // 基础套餐
      basicmealShow: false,
      //可选套餐
      optionalmealShow: false,
      radio: "",
      oldRadio: "",
      //可选项目
      optionalcombShow: false,
      ybShow: false,
      jcShow: false,
      jyShow: false,
      qtShow: false,
      result: [],
      //测试数据
      list: {
        limit: 100,
        //基础套餐
        Basicmeal: {
          mealname: "核酸检测套餐-单采",
          amount: 50,
          detail: [
            {
              itemname: "单采项目a",
              itemintrod: "单采项目a单采项目a单采项目a单采项目a单采项目a",
              price: 10
            },
            {
              itemname: "单采项目b",
              itemintrod:
                "单采项目b单采项目b单采项目b单采项目b单采项目b单采项目b",
              price: 10
            },
            {
              itemname: "单采项目c",
              itemintrod:
                "单采项目c单采项目c单采项目c单采项目c单采项目c单采项目c单采项目c",
              price: 10
            }
          ]
        },
        //体检套餐
        Optionalmeals: [
          {
            mealname: "体检套餐1",
            checked: false,
            detail: [
              {
                itemname: "体检项目a",
                itemintrod:
                  "体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a",
                price: 10
              },
              {
                itemname: "体检项目b",
                itemintrod:
                  "体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b",
                price: 10
              },
              {
                itemname: "体检项目c",
                itemintrod:
                  "体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c",
                price: 10
              }
            ]
          },
          {
            mealname: "体检套餐2",
            checked: false,
            detail: [
              {
                itemname: "体检项目a",
                itemintrod:
                  "体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a",
                price: 20
              },
              {
                itemname: "体检项目b",
                itemintrod:
                  "体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b",
                price: 20
              },
              {
                itemname: "体检项目c",
                itemintrod:
                  "体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c",
                price: 20
              }
            ]
          },
          {
            mealname: "体检套餐3",
            checked: false,
            detail: [
              {
                itemname: "体检项目a",
                itemintrod:
                  "体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a体检项目a",
                price: 30
              },
              {
                itemname: "体检项目b",
                itemintrod:
                  "体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b体检项目b",
                price: 30
              },
              {
                itemname: "体检项目c",
                itemintrod:
                  "体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c体检项目c",
                price: 30
              }
            ]
          }
        ],
        //自选项目
        Optionalcomb: [
          {
            itemname: "自选项目a",
            itemintrod:
              "自选项目a自选项目a自选项目a自选项目a自选项目a自选项目a自选项目a自选项目a自选项目a自选项目a",
            price: 10,
            itemtype: 0,
            checked: false
          },
          {
            itemname: "自选项目b",
            itemintrod:
              "自选项目b自选项目b自选项目b自选项目b自选项目b自选项目b自选项目b自选项目b自选项目b自选项目b",
            price: 20,
            itemtype: 1,
            checked: false
          },
          {
            itemname: "自选项目c",
            itemintrod:
              "自选项目c自选项目c自选项目c自选项目c自选项目c自选项目c自选项目c自选项目c自选项目c自选项目c",
            price: 30,
            itemtype: 2,
            checked: false
          },
          {
            itemname: "自选项目d",
            itemintrod:
              "自选项目d自选项目d自选项目d自选项目d自选项目d自选项目c自选项目d自选项目d自选项目d自选项目d",
            price: 40,
            itemtype: 3,
            checked: false
          }
        ]
      },
      // 搜索列表
      searchList: {
        //搜索套餐
        searchmeals: [
          {
            mealname: "搜索套餐1",
            checked: false,
            detail: [
              {
                itemname: "搜索套餐项目a",
                itemintrod:
                  "搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a",
                price: 10
              },
              {
                itemname: "搜索套餐项目b",
                itemintrod:
                  "搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b",
                price: 10
              },
              {
                itemname: "搜索套餐项目c",
                itemintrod:
                  "搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c",
                price: 10
              }
            ]
          },
          {
            mealname: "搜索套餐2",
            checked: false,
            detail: [
              {
                itemname: "搜索套餐项目a",
                itemintrod:
                  "搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a",
                price: 20
              },
              {
                itemname: "搜索套餐项目b",
                itemintrod:
                  "搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b",
                price: 20
              },
              {
                itemname: "搜索套餐项目c",
                itemintrod:
                  "搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c",
                price: 20
              }
            ]
          },
          {
            mealname: "搜索套餐3",
            checked: false,
            detail: [
              {
                itemname: "搜索套餐项目a",
                itemintrod:
                  "搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a搜索套餐项目a",
                price: 30
              },
              {
                itemname: "搜索套餐项目b",
                itemintrod:
                  "搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b搜索套餐项目b",
                price: 30
              },
              {
                itemname: "搜索套餐项目c",
                itemintrod:
                  "搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c搜索套餐项目c",
                price: 30
              }
            ]
          }
        ],
        //搜索项目
        searchcomb: [
          {
            itemname: "搜索项目a",
            itemintrod:
              "搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a搜索项目a",
            price: 10,
            itemtype: 0,
            checked: false
          },
          {
            itemname: "搜索项目b",
            itemintrod:
              "搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b搜索项目b",
            price: 20,
            itemtype: 1,
            checked: false
          },
          {
            itemname: "搜索项目c",
            itemintrod:
              "搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c搜索项目c",
            price: 30,
            itemtype: 2,
            checked: false
          },
          {
            itemname: "搜索项目d",
            itemintrod:
              "搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d搜索项目d",
            price: 40,
            itemtype: 3,
            checked: false
          }
        ]
      }
    };
  },
  created() {
    let list = this.list.Basicmeal.detail;
    list.forEach(value => {
      this.basicmealPrice = this.basicmealPrice + value.price;
    });
    this.totalPrice = this.basicmealPrice;
  },
  methods: {
    onSearch(val) {
      this.searchStr = val.trim();
      if (this.searchStr.length !== 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    onCancel() {
      this.searchStr = "";
      this.show = false;
    },
    onClear() {
      this.searchStr = "";
      this.show = false;
    },
    searchmealListClick(index) {
      this.searchList.searchmeals[index].checked = !this.searchList.searchmeals[
        index
      ].checked;
    },
    selectSearchMealClick(mealname) {
      if (mealname === this.searchOldRadio) {
        this.searchRadio = "";
        this.searchOldRadio = "";
      } else {
        this.searchOldRadio = mealname;
      }
      this.totalPrice = 0;
      this.searchmealPrice = 0;
      let list = this.searchList.searchmeals;
      let indexTreat = list.findIndex(item => {
        return item.mealname === this.searchRadio;
      });
      if (indexTreat !== -1) {
        list[indexTreat].detail.forEach(value => {
          this.searchmealPrice = this.searchmealPrice + value.price;
        });
      }
      this.totalPrice =
        this.searchmealPrice +
        this.searchcombPrice +
        this.basicmealPrice +
        this.optionalmealPrice +
        this.optionalcombPrice;
    },
    searchcombListClick(index) {
      this.searchList.searchcomb[index].checked = !this.searchList.searchcomb[
        index
      ].checked;
    },
    selectSearchCombChange(e) {
      this.totalPrice = 0;
      this.searchcombPrice = 0;
      this.combResult = e;
      let list = this.searchList.searchcomb;
      for (let i = 0; i < this.combResult.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (this.combResult[i] === list[j].itemname) {
            this.searchcombPrice = this.searchcombPrice + list[j].price;
          }
        }
      }
      this.totalPrice =
        this.searchmealPrice +
        this.searchcombPrice +
        this.basicmealPrice +
        this.optionalmealPrice +
        this.optionalcombPrice;
    },
    basicmealShowClick() {
      this.basicmealShow = !this.basicmealShow;
    },
    optionalmealShowClick() {
      this.optionalmealShow = !this.optionalmealShow;
    },
    optionalmealListClick(index) {
      this.list.Optionalmeals[index].checked = !this.list.Optionalmeals[index]
        .checked;
    },
    selectMealClick(mealname) {
      if (mealname === this.oldRadio) {
        this.radio = "";
        this.oldRadio = "";
      } else {
        this.oldRadio = mealname;
      }
      this.totalPrice = 0;
      this.optionalmealPrice = 0;
      let list = this.list.Optionalmeals;
      let indexTreat = list.findIndex(item => {
        return item.mealname === this.radio;
      });
      if (indexTreat !== -1) {
        list[indexTreat].detail.forEach(value => {
          this.optionalmealPrice = this.optionalmealPrice + value.price;
        });
      }
      this.totalPrice =
        this.searchmealPrice +
        this.searchcombPrice +
        this.basicmealPrice +
        this.optionalmealPrice +
        this.optionalcombPrice;
    },
    optionalcombShowClick() {
      this.optionalcombShow = !this.optionalcombShow;
    },
    optionalcombListClick(index) {
      this.list.Optionalcomb[index].checked = !this.list.Optionalcomb[index]
        .checked;
    },
    ybShowClick() {
      this.ybShow = !this.ybShow;
    },
    jcShowClick() {
      this.jcShow = !this.jcShow;
    },
    jyShowClick() {
      this.jyShow = !this.jyShow;
    },
    qtShowClick() {
      this.qtShow = !this.qtShow;
    },
    selectCombChange(e) {
      this.totalPrice = 0;
      this.optionalcombPrice = 0;
      this.result = e;
      let list = this.list.Optionalcomb;
      for (let i = 0; i < this.result.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (this.result[i] === list[j].itemname) {
            this.optionalcombPrice = this.optionalcombPrice + list[j].price;
          }
        }
      }
      this.totalPrice =
        this.searchmealPrice +
        this.searchcombPrice +
        this.basicmealPrice +
        this.optionalmealPrice +
        this.optionalcombPrice;
    },
    order() {
      if (this.totalPrice === this.basicmealPrice) {
        this.$toast.fail("请选择体检套餐或体检项目");
      } else if (
        this.totalPrice < this.list.limit ||
        this.totalPrice === this.list.limit
      ) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "确认提交？"
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else if (this.totalPrice > this.list.limit) {
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "已超出单位额度" +
              this.list.limit +
              "，超出部分将由个人支付，确认提交？"
          })
          .then(() => {
            this.$router.push({
              path: "/team/order"
            });
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style scope>
.add_container .search {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.add_container .search .van-field__control[type="search"] {
  height: 60px !important;
  font-size: 40px !important;
}
.add_container .search .van-field__left-icon .van-icon {
  font-size: 40px !important;
  line-height: 64px !important;
}
.add_container .search .van-search__action {
  font-size: 40px !important;
  padding: 0 20px !important;
}
.add_container .meal_list,
.add_container .result {
  padding-bottom: 160px;
}
.add_container .van-icon {
  font-size: 40px;
}
.add_container .desc {
  color: gray;
}
.add_container .searchmeal .title,
.add_container .basicmeal .title,
.add_container .optionalmeal .title,
.add_container .optionalcomb .title {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 120px;
  font-size: 40px;
  background-color: #eee;
  border-bottom: 2px solid white;
}
.add_container .searchmeal .title span,
.add_container .basicmeal .title span,
.add_container .optionalmeal .title span,
.add_container .optionalcomb .title span {
  flex: 1;
  padding: 0 10px;
}
.add_container .searchmeal .item .detail,
.add_container .basicmeal .list .item,
.add_container .optionalmeal .item .detail,
.add_container .optionalcomb .list .item .desc {
  font-size: 40px;
  border-bottom: 10px solid #eee;
  padding: 10px 30px;
  line-height: 70px;
}
.add_container .searchmeal .item .label,
.add_container .optionalmeal .item .label,
.add_container .optionalcomb .list .item .label {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 120px;
  font-size: 40px;
  border-bottom: 10px solid #eee;
  width: 100%;
}
.add_container .searchmeal .item .label span,
.add_container .optionalmeal .item .label span,
.add_container .optionalcomb .list .item .label span {
  flex: 1;
  padding: 0 10px;
  margin-left: -10px;
}
.add_container .searchmeal .item .label .van-radio__icon,
.add_container .optionalmeal .item .label .van-radio__icon {
  height: 100%;
}
.add_container .optionalcomb .list .item .label .van-checkbox__icon {
  height: 100%;
}
.add_container .buttom {
  position: fixed;
  bottom: 60px;
  left: 0;
  display: flex;
  height: 100px;
  width: 100%;
}
.add_container .buttom .van-button {
  height: 100%;
  width: 50%;
  border-radius: 0;
  font-size: 40px;
}
.add_container .buttom .total {
  width: 50%;
  font-size: 40px;
  line-height: 100px;
  text-align: center;
  color: white;
  background-color: skyblue;
}
</style>